import React from 'react';

import { Building, Road, Drop } from '../../assets/Icons';

interface Props {
  type: string;
}

const ProjectBadge: React.FC<Props> = ({ type }) => {
  const parentClassnames =
    'inline-block rounded-lg p-2 absolute -left-2.5 -bottom-2.5';

  if (type === 'visokogradnja') {
    return (
      <div className={`${parentClassnames} bg-yellow-50`}>
        <Building className="h-7 w-7 text-yellow-900" />
      </div>
    );
  }

  if (type === 'niskogradnja') {
    return (
      <div className={`${parentClassnames} bg-gray-50`}>
        <Road className="h-7 w-7 text-gray-900" />
      </div>
    );
  }

  if (type === 'hidrogradnja') {
    return (
      <div className={`${parentClassnames} bg-blue-50`}>
        <Drop className="h-7 w-7 text-blue-700" />
      </div>
    );
  }

  return null;
};

export default ProjectBadge;
