import React from 'react';
import { LocationMarker, Mail, Phone } from '../assets/Icons';
import type { Address } from '../types';

interface Props {
  address: Address;
  email: string;
  phoneNumber: string;
}

const Contact: React.FC<Props> = ({ address, email, phoneNumber }) => {
  const phoneNumberStripped = phoneNumber.replace(/[\/-\s]/gi, '');

  return (
    <section className="bg-blue-50 bg-opacity-50 text-center" id="kontakt">
      <div className="mx-auto p-4 py-28 md:container">
        <h2 className="text-4xl font-bold tracking-tight text-blue-800 md:text-5xl">
          Kontaktirajte nas
        </h2>

        <p className="mt-5 text-blue-900 md:text-lg">
          Recite kako Vam možemo pomoći.
        </p>
        <p className="mt-9 text-blue-700 sm:mt-11 md:text-lg">
          Naša kancelarija se nalazi na adresi <br className="sm:hidden" />
          <LocationMarker className="ml-3 inline h-4 w-4 align-baseline" />{' '}
          {address.line1}, {address.line2}.
        </p>

        <div className="mx-auto mt-16 flex flex-col items-center justify-center space-y-10 text-left max-sm:max-w-md sm:flex-row sm:space-y-0 sm:space-x-5 lg:space-x-10">
          <a
            className="hover:card w-full lg:w-auto"
            href={`mailto:${email}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="rounded-lg bg-blue-100 bg-opacity-30 p-10 transition-colors hover:bg-opacity-60 md:w-90">
              <div>
                <Mail className="h-8 w-8 fill-white text-blue-700" />
              </div>

              <div className="mt-6 text-xl font-medium tracking-tight text-blue-800">
                Pošaljite nam email
              </div>

              <div className="tracking-tight text-blue-800">{email}</div>
            </div>
          </a>

          <a
            className="hover:card w-full lg:w-auto"
            href={`tel:${phoneNumberStripped}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="rounded-lg bg-blue-100 bg-opacity-30 p-10 transition-colors hover:bg-opacity-60 md:w-90">
              <div>
                <Phone className="h-8 w-8 fill-white text-blue-700" />
              </div>

              <div className="mt-6 text-xl font-medium tracking-tight text-blue-800">
                Pozovite nas
              </div>

              <div className="tracking-tight text-blue-800">{phoneNumber}</div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
