import React from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import clsx from 'clsx';

import ProjectBadge from './ProjectBadge';
import { ConstructionType, Position, ImageWithAlt } from '../../types';

interface Props {
  href: string;
  image: ImageWithAlt;
  ongoing?: boolean;
  position: Position;
  title: React.ReactNode;
  type?: ConstructionType;
}

const Card: React.FC<Props> = ({
  href,
  image,
  ongoing,
  title,
  type,
  position,
}) => {
  return (
    <Link className="group" to={href}>
      <figure>
        <div className="relative">
          <div
            className={clsx([
              position === Position.landscape && 'aspect-h-4 aspect-w-7',
              position === Position.portrait && 'aspect-h-7 aspect-w-6',
            ])}
          >
            <GatsbyImage
              alt={image.alt}
              className="!absolute rounded-lg object-cover"
              fluid={image.asset.fluid}
            />
          </div>

          {type && <ProjectBadge type={type.toLowerCase()} />}
        </div>

        <figcaption
          className={clsx([
            'mt-2 flex items-start px-1.5 text-lg font-medium text-gray-600 group-hover:text-blue-600 group-hover:underline',
            position === Position.landscape && 'md:text-base xl:text-xl',
            position === Position.portrait &&
              'text-center sm:text-base md:text-lg lg:text-xl',
          ])}
        >
          {ongoing && (
            <span className="mr-1 mt-1 whitespace-nowrap rounded-full bg-cyan-50 px-2 py-0.5 text-xs font-extrabold uppercase text-cyan-700">
              U toku
            </span>
          )}

          {title}
        </figcaption>
      </figure>
    </Link>
  );
};

export default Card;
