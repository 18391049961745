import type { FluidObject } from 'gatsby-image';
import type { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import type {
  SanityImageSource as _SanityImageSource,
  SanityImageObject as _SanityImageObject,
} from '@sanity/image-url/lib/types/types';

export type ImageURLBuilder = ImageUrlBuilder;
export type SanityImageSource = _SanityImageSource;
export type SanityImageObject = _SanityImageObject;

export type ConstructionType =
  | 'visokogradnja'
  | 'niskogradnja'
  | 'hidrogradnja';

export enum Position {
  portrait,
  landscape,
}

export type SingleImageResponse = {
  childImageSharp: {
    fluid: FluidObject;
  };
};

export type ImageResponseEdge = {
  node: {
    childImageSharp: {
      fluid: FluidObject & {
        originalName: string;
      };
    };
  };
};

export interface ImageWithAlt {
  alt: string;
  asset: {
    _id?: string;
    url?: string;
    fluid: FluidObject;
  };
}

export type AvailableColors = 'blue' | 'yellow' | 'gray';

export interface Category {
  name: ConstructionType;
  color?: AvailableColors;
}

export interface Slug {
  current: string;
}

export interface Feature {
  title: string;
  items: Array<string>;
  linkWithDescription: {
    url: string;
    description: string;
  };
  description: string;
  color?: AvailableColors;
  image: ImageWithAlt;
}

export interface Address {
  line1: string;
  line2: string;
}
