import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Card from './shared/Card';
import ExternalLinkIcon from './shared/ExternalLinkIcon';
import { Category, ImageWithAlt, Position, Slug } from '../types';

interface Props {
  projects: Array<{
    _id: string;
    title: string;
    slug: Slug;
    ongoing: boolean;
    category: Category;
    image: ImageWithAlt;
  }>;
}

const FeaturedProjects: React.FC<Props> = ({ projects }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "rest-projects.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <section className="container">
      <h2 className="mb-9 text-3xl font-medium md:text-4xl lg:text-5xl">
        Projekti
      </h2>

      <div className="grid gap-x-5 gap-y-10 sm:grid-cols-2 xl:grid-cols-3">
        {projects.map((project) => (
          <Card
            href={'/projekti/' + project.slug.current}
            image={project.image}
            key={project._id}
            ongoing={project.ongoing}
            position={Position.landscape}
            title={<h3>{project.title}</h3>}
            type={project.category.name}
          />
        ))}

        <div className="text-center">
          <Card
            href="/projekti/"
            image={{
              alt: 'pogledaj sve projekte',
              asset: data.file.childImageSharp,
            }}
            key="view-all-projects"
            position={Position.landscape}
            title={
              <span className="group text-center hover:text-blue-600 hover:underline">
                Pogledaj ostale projekte
                <ExternalLinkIcon />
              </span>
            }
          />
        </div>
      </div>
    </section>
  );
};

export default FeaturedProjects;
